import service from '@/utils/request2'

//流水详情
export function getFlowDetails(params){
    return service({
        url:'api/api/distribution/info',
        method:'get',
        params
    })
}

//用户提现申请
export function ApplyWithdrawal(data){
    return service({
        url:'api/api/distribution/withdrawal',
        method:'post',
        data
    })
}
