<template>
    <div>
        <Menu></Menu>
        <div class="w1200 m-auto">
            <p class="fs14 color-1 mt10"></p>
            <div class="videoBox">
                <div class="topBox">
                    <div>
                        <p>￥{{ obj.balance }}</p>
                        <p class="mt20">可提现金额</p>
                    </div>
                    <div>
                        <p>￥{{ obj.frozen_price }}</p>
                        <p class="mt20">冻结金额（提现中）</p>
                    </div>
                    <div @click="is_show = 1">
                        申请提现
                    </div>
                </div>
                <table class="table">
                    <tr class="tableTop">
                        <td>变更金额</td>
                        <td>变更原因</td>
                        <td>变更时间</td>
                    </tr>
                    <tr v-for="(item, index) in tableList" :key="index">
                        <td>{{ item.distribution_price }}</td>
                        <td>{{ item.log_type }}</td>
                        <td>{{ item.add_time }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-if="is_show == 1" class="dialogBox">
            <!--  选择报名专业 -->
            <el-dialog title="申领提现" :visible.sync="dialogVisible">
                <div class="box">
                    <div class="dialogTop">
                        <div v-for="item in dialogList" :key="item" @click="dialogFn(item)">
                            {{ item }} 元
                        </div>
                    </div>
                    <div class="form">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px"
                            class="demo-ruleForm">
                            <el-form-item label="自定义金额：" prop="num">
                                <el-input v-model="ruleForm.num" type="number"></el-input>
                            </el-form-item>
                        </el-form>
                        <span class="mt20"> 元</span>
                    </div>
                    <div class="btnBox">
                        <div class="cancel " @click="is_show = 0">取消</div>
                        <div class="ml15 Submit" @click="ApplyWithdrawalFn('ruleForm')">申请提现</div>
                    </div>
                </div>

            </el-dialog>
        </div>
        <div v-if="is_show == 2" class="dialogBox">
            <!--  支付成功 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <img src="@/../public/image/cg.png" width="113px" alt="">
                    <p class="fs22">申请成功</p>
                    <p class="mt20">您的提现申请已提交</p>
                    <p>请耐心等待工作人员审核</p>
                </div>
                <div class="btnBox">
                    <div class="complete">完成</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 3" class="dialogBox">
            <!--  支付失败 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <img src="@/../public/image/sb.png" width="113px" alt="">
                    <p class="fs22">申请失败</p>
                    <p class="mt20">您的可提现余额不足</p>
                </div>
                <div class="btnBox">
                    <div class="complete">完成</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import Menu from '@/components/menu.vue'
import { getFlowDetails, ApplyWithdrawal } from '@/api/ApplyWithdrawal'
import { is_QRcode } from '@/api/PublicityApplication'
var checkAge = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('金额不能为空'));
    }
    setTimeout(() => {
        console.log(!(value >= 100))
        if (!(value >= 100)) {
            callback(new Error('提现金额需大于等于一百元'));
        } else {
            callback();
        }
    }, 100);
};
export default {
    components: { Menu, },
    data() {
        return {
            is_show: 0,
            dialogVisible: true,
            ruleForm: {
                num: 0,
            },
            obj: {

            },
            dialogList: [100.00, 200.00, 300.00],

            rules: {
                num: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { validator: checkAge, trigger: 'blur' }
                ],
            },
            tableList: []
        }
    },
    mounted() {
        localStorage.setItem('num', 4)
        getFlowDetails().then(res => {
            if (res.code == 200) {
                console.log(res)
                this.tableList = res.data
                console.log(this.tableList)
                // this.tableList.forEach(item=>{
                //     item.add_time = this.$commit.timestampToTime(item.add_time)
                // })
            }
        })
        is_QRcode().then(res => {
            if (res.code == 200) {
                console.log(res)
                this.obj = res.data
            }
        })
    },
    methods: {
        //选择提现金额
        dialogFn(data) {
            this.ruleForm.num = data
        },
        ApplyWithdrawalFn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    ApplyWithdrawal({ amount: this.ruleForm.num }).then(res => {
                        console.log(res)
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    },
    watch: {
        dialogVisible(val) {
            if (val == false) {
                this.dialogVisible = true
                this.is_show = 0
            }
        }
    }
}
</script>
  
<style  scoped>
.videoBox {
    margin-top: 20px;
    width: 1200px;
    height: 860px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.video {
    width: 1080px;
    height: 577px;
    margin: 20px auto;
    background: #D4D4D4;
}

.topBox {
    width: 1198px;
    height: 160px;
    background: #FFF5F5;
    display: flex;
    justify-content: space-between;
    padding: 30px 80px;
    box-sizing: border-box;
    align-items: center;
}

.topBox>div>p:nth-child(1) {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #CD0000;
    line-height: 30px;
}

.topBox>div>p:nth-child(2) {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
}

.topBox div:nth-child(3) {
    margin-top: auto;
    width: 150px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    line-height: 48px;
    cursor: pointer;
}

.table {
    border-right: 1px solid #E7E7E7;

    border-bottom: 1px solid #E7E7E7;

    border-collapse: collapse;
    margin: 20px auto;
    width: 1112px;
}

.table tr td:nth-child(1) {
    text-align: center;
}

.table tr td:nth-child(2) {
    text-indent: 2em;
}

.table tr td:nth-child(3) {
    text-align: center;
}

.tableTop {
    background: #FFF5F5;
}

.tableTop td {
    border: none !important;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500 !important;
    color: #CD0000 !important;
    line-height: 42px;
    text-align: center;
}

.tableTop td:nth-child(1) {
    width: 245px;
    border: 1px solid #E7E7E7;
}

.tableTop td:nth-child(2) {
    width: 623px;
    border: 1px solid #E7E7E7;
}

.tableTop td:nth-child(3) {
    width: 246px;
    border: 1px solid #E7E7E7;
}

.table td {
    height: 42px;
    border-left: 1px solid #E7E7E7;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    border-top: 1px solid #E7E7E7;
}

.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 50px;
}

.cancel {
    width: 200px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #CC0000;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.Submit {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.box {
    padding: 0 70px;
    box-sizing: border-box;
}

.dialogTop {
    display: flex;
    justify-content: space-between;
}

.dialogTop>div {
    width: 170px;
    height: 90px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 9px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    line-height: 90px;
}

.form {
    display: flex;
    margin: 20px auto;
}

.form>>>.el-input__inner {
    width: 417px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #666666;
}

.complete {
    width: 440px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.PaymentSucceeded {
    text-align: center;
    margin-bottom: 20px;
}
</style>